import * as React from "react";
import { graphql } from "gatsby";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { getImage } from "gatsby-plugin-image";

// Components
import Seo from "../../components/SEO";

// Template
import ProductPage from "../../templates/product-page";

// Assets
import Customers1Icon from "../../images/icons/product-customers-1.svg";
import Customers2Icon from "../../images/icons/product-customers-2.svg";
import Customers3Icon from "../../images/icons/product-customers-3.svg";
import Brands1Icon from "../../images/icons/product-brands-1.svg";
import Brands2Icon from "../../images/icons/product-brands-2.svg";
import Brands3Icon from "../../images/icons/product-brands-3.svg";
import Plan1Icon from "../../images/icons/product-plans-1.svg";
import Plan2Icon from "../../images/icons/product-plans-2.svg";
import Plan3Icon from "../../images/icons/product-plans-3.svg";

const ProductsPage = ({
  data: { heroSectionImage, featuresSectionImages, relatedBlogs },
}) => {
  const { t } = useTranslation();
  return (
    <ProductPage
      heroSection={{
        title: (
          <Trans
            i18nKey="zealApp.heroSection.title"
            values={{ highlight: t("zealApp.heroSection.title.highlight") }}
            components={[<span />]}
          />
        ),
        description: t("zealApp.heroSection.description"),
        button: t("zealApp.heroSection.button"),
        image: heroSectionImage,
      }}
      featuresSection={{
        title: t("zealApp.featuresSection.title"),
        description: t("zealApp.featuresSection.description"),
        features: [
          {
            title: t("zealApp.featuresSection.insightfulData.title"),
            description: t(
              "zealApp.featuresSection.insightfulData.description"
            ),
            image: getImage(
              featuresSectionImages.edges[0].node.childImageSharp
            ),
          },
          {
            title: t("zealApp.featuresSection.payment.title"),
            description: t("zealApp.featuresSection.payment.description"),
            image: getImage(
              featuresSectionImages.edges[1].node.childImageSharp
            ),
          },
          {
            title: t("zealApp.featuresSection.ordering.title"),
            description: t("zealApp.featuresSection.ordering.description"),
            image: getImage(
              featuresSectionImages.edges[2].node.childImageSharp
            ),
          },
          {
            title: t("zealApp.featuresSection.feedback.title"),
            description: t("zealApp.featuresSection.feedback.description"),
            image: getImage(
              featuresSectionImages.edges[3].node.childImageSharp
            ),
          },
        ],
        button: {
          text: t("zealApp.featuresSection.button"),
          href: "https://heyzine.com/flip-book/c1f49880c8.html",
        },
      }}
      howItWorksSection={{
        title: t("zealApp.howItWorks.title"),
        toggleSwitch: [
          t("zealApp.howItWorks.switcher.item1"),
          t("zealApp.howItWorks.switcher.item2"),
        ],
        items: [
          [
            {
              icon: <Customers1Icon />,
              title: t("zealApp.howItWorks.customers.app.title"),
              description: t("zealApp.howItWorks.customers.app.description"),
            },
            {
              icon: <Customers2Icon />,
              title: t("zealApp.howItWorks.customers.points.title"),
              description: t("zealApp.howItWorks.customers.points.description"),
            },
            {
              icon: <Customers3Icon />,
              title: t("zealApp.howItWorks.customers.rewards.title"),
              description: t(
                "zealApp.howItWorks.customers.rewards.description"
              ),
            },
          ],
          [
            {
              icon: <Brands1Icon />,
              title: t("zealApp.howItWorks.brands.demo.title"),
              description: t("zealApp.howItWorks.brands.demo.description"),
            },
            {
              icon: <Brands2Icon />,
              title: t("zealApp.howItWorks.brands.loyalty.title"),
              description: t("zealApp.howItWorks.brands.loyalty.description"),
            },
            {
              icon: <Brands3Icon />,
              title: t("zealApp.howItWorks.brands.monetize.title"),
              description: t("zealApp.howItWorks.brands.monetize.description"),
            },
          ],
        ],
        button: t("zealApp.howItWorks.button"),
      }}
      pricingSection={{
        title: t("zealApp.pricing.title"),
        description: t("zealApp.pricing.description"),
        switcher: {
          text: t("zealApp.pricing.switcher.text"),
          yearly: t("zealApp.pricing.plans.subtitle.yearly"),
          monthly: t("zealApp.pricing.plans.subtitle.monthly"),
        },
        plans: [
          {
            icon: <Plan1Icon />,
            title: t("zealApp.pricing.plans1.title"),
            price: {
              monthly: t("zealApp.pricing.plans1.price.monthly"),
              yearly: t("zealApp.pricing.plans1.price.yearly"),
            },

            features: [
              t("zealApp.pricing.plans1.features1"),
              t("zealApp.pricing.plans1.features2"),
              t("zealApp.pricing.plans1.features3"),
              t("zealApp.pricing.plans1.features4"),
              t("zealApp.pricing.plans1.features5"),
            ],
          },
          {
            icon: <Plan2Icon />,
            title: t("zealApp.pricing.plans2.title"),
            price: {
              monthly: t("zealApp.pricing.plans2.price.monthly"),
              yearly: t("zealApp.pricing.plans2.price.yearly"),
            },

            features: [
              t("zealApp.pricing.plans2.features1"),
              t("zealApp.pricing.plans2.features2"),
              t("zealApp.pricing.plans2.features3"),
              t("zealApp.pricing.plans2.features4"),
              t("zealApp.pricing.plans2.features5"),
            ],
          },
          {
            icon: <Plan3Icon />,
            title: t("zealApp.pricing.plans3.title"),
            price: {
              monthly: t("zealApp.pricing.plans3.price.monthly"),
              yearly: t("zealApp.pricing.plans3.price.yearly"),
            },

            features: [
              t("zealApp.pricing.plans3.features1"),
              t("zealApp.pricing.plans3.features2"),
              t("zealApp.pricing.plans3.features3"),
              t("zealApp.pricing.plans3.features4"),
              t("zealApp.pricing.plans3.features5"),
            ],
          },
        ],
        button: t("zealApp.pricing.button"),
      }}
      relatedBlogsSection={{
        title: t("zealApp.relatedBlogs.title"),
        description: t("zealApp.relatedBlogs.description"),
        blogs: relatedBlogs.nodes,
      }}
      faqSection={{
        title: t("zealApp.faq.title"),
        description: t("zealApp.faq.description"),
        FAQs: [
          {
            question: t("zealApp.faq.question1"),
            answer: t("zealApp.faq.answer1"),
          },
          {
            question: t("zealApp.faq.question2"),
            answer: t("zealApp.faq.answer2"),
          },
          {
            question: t("zealApp.faq.question3"),
            answer: t("zealApp.faq.answer3"),
          },
          {
            question: t("zealApp.faq.question4"),
            answer: t("zealApp.faq.answer4"),
          },
        ],
        buttonLink: "/contact-us",
        buttonText: t("zealApp.relatedBlogs.button"),
      }}
      yellowSection={{
        title: t("zealApp.yellowSection.title"),
        description: t("zealApp.yellowSection.description"),
        to: "/contact-us",
        btnText: t("zealApp.yellowSection.button"),
      }}
      scheduleSection={{
        title: t("home.calendly.title"),
        calendlyUrl: "https://calendly.com/zeal-io/schedule-call-with-zeal-expert"
      }}
    />
  );
};

export default ProductsPage;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "zeal-app"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    heroSectionImage: file(name: { regex: "/products-section-1/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    featuresSectionImages: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        name: { regex: "/products-key-feature/" }
      }
      sort: { order: ASC, fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }

    relatedBlogs: allGhostPost(
      filter: {
        slug: {
          in: [
            "boost-your-business-with-payment-linked-loyalty"
            "what-is-pos-and-why-is-it-so-important"
            "how-zealpay-is-made-to-help-you-boost-your-customer-base"
          ]
        }
      }
    ) {
      nodes {
        title
        feature_image
        url
        excerpt
        created_at(formatString: "MMM YYYY")
        reading_time
      }
    }
  }
`;
export const Head = ({ pageContext }) => {
  const isArabic = pageContext.i18n.language === "ar";
  return (
    <Seo isArabic={isArabic} title={isArabic ? "Zeal Apps" : "Zeal Apps"} />
  );
};
